@import '_shared';

body {
	background-color: $body-blue;
}

#header {
	.title,
	.users {
		position: relative;
		display: inline-block;
		border: 2px solid $purple;
		border-radius: 20px;
		color: $white;
		padding: 6px 16px;
	}
	
	.title {
		margin: 10px 0 0 0;
		font-weight: bold;
		z-index: 2;
		background-color: $purple;

		@include media-breakpoint-down(sm) {
			border-bottom-left-radius: 0;
		}

		&:hover {
			text-decoration: none;
		}
	}
	
	.users {
		background-color: $green;
		border-radius: 0 20px 20px 0;
		z-index: 1;
		transform: translateX(-100%);
		padding-left: 40px;
		transition: transform .4s ease-in-out;
		
		&.active {
			transform: translateX(-30px);
		}
	}

	ul.navList {
		list-style-type: none;
		padding: 10px 0 0 0;
		margin-top: 10px;
		margin: 0;

		li {
			padding: 0;
			margin: 0;
			color: $white;
			text-align: center;
			display: inline-block;

			a.googleAuth {
				display: inline-block;
				color: $white;
				background: $purple url('../img/circle_g.svg') center left 4px no-repeat;
				background-size: 30px;
				padding: 6px 16px 6px 44px;
				border: 2px solid $purple;
				border-radius: 20px;

				&:hover {
					text-decoration: none;
				}
			}

			&.userName {
				padding: 6px 16px;
				border: 2px solid $purple;
				border-radius: 20px;
				background: $purple;
				font-weight: normal;		
				z-index: 1;		
			}
			
			&.logout {
				margin-right: -30px;

				button {
					padding: 6px 16px 6px 40px;
					border: 2px solid $purple;
					border-radius: 0 20px 20px 0;
					background: $white;
					font-weight: normal;
					transform: translateX(-30px);
					color: $black;
					
					&:active,
					&:hover {
						background: $off-white;
					}
				}	
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 0;

			li {
				&.userName {
					font-size: .75rem;
					border-radius: 0 0 20px 20px;
				}

				a.googleAuth {
					border-radius: 0 0 20px 20px;
					background-size: 20px;
					font-size: .75rem;
					background-position: center left 12px;
					padding-left: 42px;
				}
				
				&.logout {
					button {
						font-size: .75rem;
					}
				}
			}
		}
	}

	#loginWarning {
		text-align: center;

		> div {
			display: inline-block;
			font-weight: bold;
			padding: 10px 20px;
			background: $red;
			margin: 30px 0 10px 0;
			border-radius: 10px;
			color: $white;
			font-size: 16px;
			border: 3px solid $yellow;

			> span {
				display: block;
				font-weight: normal;
				font-style: italic;
				padding-top: 6px;
			}
		}
	}
}

#footer {
	padding: 0;

	.content {
		color: $white;
		text-align: center;
		font-size: 14px;
		padding: 20px 0;
		font-style: italic;
		
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			
			li {
				padding: 0;
				margin: 0;

				&.adminLogin {
					a {
						color: $white;
						text-decoration: underline;
					}
				}
			}
		}
	}

	.donations {
		padding-top: 20px;
		margin-top: 20px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			padding: 20px 30px 0 30px;
		}

		.title {
			display: inline-block;
			margin-right: 10px;
			font-size: 15px;
			color: $white;
			padding: 4px 8px;
			background: $darkGray;
			box-shadow: 3px 3px #999;
			margin-bottom: 10px;
		}

		a {
			display: inline-block;
			padding: 4px 8px;
			background: $purple;
			margin-right: 10px;
			color: $bright-red;
			box-shadow: 3px 3px $offer-white;
			//font-weight: bold;
			font-size: 15px;
			margin-bottom: 10px;

			&:hover {
				text-decoration: none;
				color: $yellow;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

//tooltips (used for donations)
.tooltip-inner {
	background-color: rgba(29,29,33,0.75);
}

//fixes for stickyness
body {
	min-height: 100vh;

	div#root {
		min-height: 100vh;

		> div {
			min-height: 100vh;
			display: flex;
			flex-direction: column;

			> div {
				flex: 1 0 auto;
			}

			#Announce,
			#footer,
			#header {
				flex: none;
			}
		}
	}
	
	@include media-breakpoint-down(xs) {
		min-height: auto;
		min-height: -webkit-fill-available;
		
		div#root {
			min-height: auto;
			min-height: -webkit-fill-available;
			
			>div {
				min-height: auto;
				min-height: -webkit-fill-available;
			}
		}
	}
}